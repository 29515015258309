import React, { Suspense, lazy } from "react";
import { motion } from 'framer-motion'
import Grid from '@mui/material/Grid';
import { Container } from "@mui/material";
import { useTheme } from "@mui/material";
import SeparatorAbsSvg2 from "../../_shared/shaping/SeparatorAbsSvg2";
import AnimateScrollIntoViewport from "../../_shared/AnimateScrollIntoViewport";
import { useBoop } from "../../../utils/useBoop";
import FrameMaskForDiv from "../../_shared/FrameMaskForDiv";
import FrameBgForDiv from "../../_shared/FrameBgForDiv";
import jpgVodaVLeseW1200h900DreamSmoothing from "../../../../static/sf/im/bg/voda-v-lese-w1200h900-dreamSmoothing-q10.jpg"
import { POLYGON_RECTANGLE_10_POINTS_RIGHT, POLYGON_STAR, POLYGON_RECTANGLE_10_POINTS_LEFT } from "../../../utils/clipPathsConstants";
import KontaktTitleWithLazyBL from "./KontaktTitleWithLazyBL";
import { useBreakpoint } from "../../_shared/contextBreakpointMediaQ";

const LazyAnimateWavingTexts = lazy(() => import(/* webpackMode: "lazy", webpackPreload: true, webpackChunkName: 'AnimateWavingTextsLazy' */ '../../_shared/AnimateWavingText'));
const LazyKontaktForm = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'KontaktFormLazy' */ './KontaktForm'));
const LazyAnimateSvg = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: 'AnimateSvgLazy' */ '../../_shared/AnimateSvg'));


const writeEmoji = "✍️"
const callMeHandEmoji = "🤙"

const styleSxWrap = {
    px: [ 0.5, 1, 1, 2 ],
    pt: [ 0, 0, 4, 6 ],
    my: 0,
    bgcolor: "primary2.main",
    color: "primary2.contrastText",
    background: (theme) => theme.gradients.lin3(0, theme.palette.primary2.main, "0%", theme.palette.primary2.light, "50%", theme.palette.primary2.main, "100%",),

    "& .gr": {
        mb: "4rem"
    },
    "& .gridItemLeft, .gridItemRight,, .gridItemMid": {
        px: 0.25,
        py: [ 0, 0, 1, 2 ],

        background: (theme) => theme.gradients.lin3(0, theme.palette.primary2.main, "0%", theme.palette.primary2.light, "50%", theme.palette.primary2.main, "100%",),
        "& .paper": {
            pt: 0,
            textAlign: 'center',
            color: "text.secondary",
            borderRadius: "20px",
            "& .MuiPaper-elevation1": {
                boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
            },

            "& .nm": {
                m: "5px",
            },
            "& .svgIcon": {
                "&.MuiSvgIcon-root": {
                    userSelect: "none",
                    display: "inline-block",
                    flexShrink: 0,
                    width: "1em",
                    height: "1em",
                    "& .telPath": {
                        stroke: (theme) => theme.palette.secondary.main,
                        fill: (theme) => theme.palette.secondary.contrastText,
                    }
                },
            }
        },
    },


}

const varDivPaper = {
    initial: false,
    animate: {
        opacity: 1,
        y: 0,
        x: 0,
        transition: {
            ease: 'circInOut',
            duration: 0.2
        },
    },
}


function MainUp(props) {

    const { title } = props
    const breakpoint = useBreakpoint()
    const theme = useTheme()

    const { isBooped: isBooped1, variants: varBoop1, trigger: triggerBoop1 } = useBoop({
        timeoutMs: 3000,
        animateTo: {
            y: -40,
            rotateX: 360,
            rotateY: 0,
        },
        transitionTo: {
            type: "spring",
            stiffness: 100,
            damping: 3,
            duration: 1.5,
        },
        from: {
            y: 0,
            rotateX: 0,
        },
    })

    const { isBooped: isBooped2, variants: varBoop2, trigger: triggerBoop2 } = useBoop({
        timeoutMs: 8000,
        animateTo: {
            x: -20,
            y: 100,
            rotateY: 360,
            scale: 3,
        },
        transitionTo: {
            type: "spring",
            stiffness: 300,
            damping: 5,
            duration: 1.5,
            delay: 4,
        },
        from: {
            x: 0, y: 0, rotateY: 0, scale: 1,
        },
        initialAnimation: true
    })

    const listPaddingTopPx = 0
    const listPaddingBottomPx = 0
    const listMarginTopPx = 0
    const listMarginBottomPx = 20
    let wavingText = `Napíšte${writeEmoji} nám`
    switch (breakpoint) {
        case "xs":
            wavingText = `Napíšte${writeEmoji} nám`
            break;
        case "sm":
            break;

        default:
            wavingText = `Napíšte${writeEmoji} nám, zavolajte${callMeHandEmoji} ...`
            break;
    }

    const sepFillColor = theme.palette.primary2.dark
    const sepFillColorContrastText = theme.palette.primary2.contrastText
    const isMobile = [ "xs", "sm" ].includes(breakpoint)

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}
        >
            <Grid
                container item
                lg={12} xl={8}
                key="grid1"
                spacing={2}
                alignItems="center"
                justifyContent="space-between"
                className="cont _mainUp"
                sx={styleSxWrap}
            >
                <Grid item
                    xs={12} md={6}
                    className="gridItem"
                >

                    <div
                        className="paper MuiPaper-elevation1"
                    >

                        {!isMobile && <div                       >
                            <AnimateScrollIntoViewport
                                // durationIn={0.5}
                                // durationOut={0.2}
                                // animateFramer={{ clipPath: POLYGON_RECTANGLE_10_POINTS_LEFT, }}
                                // exitFramer={{ clipPath: POLYGON_STAR, }}
                                // styleToMerge={{ clipPath: POLYGON_STAR, }}
                                name={`kontaktTitle-${breakpoint}`}
                                key={`kontaktTitle-${breakpoint}`}
                            >
                                <KontaktTitleWithLazyBL
                                    breakpoint={breakpoint}
                                    title={title}
                                />
                            </AnimateScrollIntoViewport>
                        </div>}

                        <motion.div
                            variants={varDivPaper}
                            initial="initial"
                            animate="animate"
                            whileTap={{ scale: 0.9 }}
                            style={{ y: -100, x: -100 }}
                            key="varDivPaperLeft"
                        >
                            <div
                                className="paper MuiPaper-elevation1"
                            >
                                <motion.div
                                    className="nm"
                                    variants={varBoop1({ index: 1 })}
                                    animate={isBooped1 ? "boop" : "rest"}
                                    onMouseEnter={triggerBoop1}
                                    key="varDivPaperLeft-varBoop1-name"
                                >
                                    František Chromek
                                </motion.div>
                                <div
                                    className="MuiTypography-root MuiTypography-body1"
                                >
                                    <motion.svg
                                        viewBox="0 0 24 24"
                                        className="svgIcon MuiSvgIcon-root"
                                        variants={varBoop2({ index: 3 })}
                                        animate={isBooped2 ? "boop" : "rest"}
                                        onMouseEnter={triggerBoop2}
                                        focusable="false"
                                        aria-hidden="true"
                                        key="varDivPaperLeft-varBoop2-telPath"
                                        style={{ zIndex: 9999999999 }}
                                    >
                                        <path
                                            d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z"
                                            className="telPath"
                                        />
                                    </motion.svg>
                                    0949 795455
                                </div>

                            </div>
                        </motion.div>
                    </div>
                    <AnimateScrollIntoViewport
                        // durationIn={0.5}
                        // durationOut={0.2}
                        // animateFramer={{ clipPath: POLYGON_RECTANGLE_10_POINTS_RIGHT, }}
                        // exitFramer={{ clipPath: POLYGON_STAR, }}
                        styleToMergeImportant={{
                            // clipPath: POLYGON_STAR,
                            overflow: 'visible',
                            top: "20px",
                        }}
                        name="AnimateWavingTexts"
                        key="AnimateWavingTexts"
                    >
                        <Suspense>
                            <LazyAnimateWavingTexts text={wavingText} />
                        </Suspense>
                    </AnimateScrollIntoViewport>
                </Grid>
                <Grid item
                    xs={12} md={6}
                    className="gridItem"
                >
                    <AnimateScrollIntoViewport
                        durationIn={0.5}
                        durationOut={0.2}
                        animateFramer={{ clipPath: POLYGON_RECTANGLE_10_POINTS_RIGHT, }}
                        exitFramer={{ clipPath: POLYGON_STAR, }}
                        styleToMerge={{
                            clipPath: POLYGON_STAR,
                        }}
                        name="KontaktForm"
                    >
                        <FrameMaskForDiv>
                            <div className="paper MuiPaper-elevation1" >
                                <Suspense>
                                    <LazyKontaktForm />
                                </Suspense>
                            </div>
                        </FrameMaskForDiv>
                    </AnimateScrollIntoViewport>
                </Grid>
            </Grid>
            <AnimateScrollIntoViewport
                name="AnimateSvg"
                durationIn={0.5}
                durationOut={0.2}
                animateFramer={{ clipPath: POLYGON_RECTANGLE_10_POINTS_LEFT, }}
                exitFramer={{ clipPath: POLYGON_STAR, }}
                styleToMerge={{ clipPath: POLYGON_STAR, }}
                key="AnimateSvg"
            >
                <SeparatorAbsSvg2
                    name="sepNormalRel2FullHorizontalHills"
                    id="AnimateSvg"
                    key="AnimateSvg"
                    optionsSep={{
                        dPathOrderNotIn: [ 1 ],
                        styleSvg: {
                            height: "100px",
                            transform: 'rotateY(180deg)'
                        },
                        svgYPadding: 0,
                        isRelative: true,
                    }} >
                    <div
                        style={{
                            position: "relative",
                            backgroundColor: sepFillColor,
                            color: sepFillColorContrastText
                        }}
                    >
                        <Container>
                            <Suspense>
                                <LazyAnimateSvg />
                            </Suspense>
                        </Container>
                    </div>
                </SeparatorAbsSvg2>
            </AnimateScrollIntoViewport>
            <AnimateScrollIntoViewport
                name="ImgInFrameMaskForDiv"
                durationIn={0.5}
                durationOut={0.2}
                animateFramer={{ clipPath: POLYGON_RECTANGLE_10_POINTS_RIGHT, }}
                exitFramer={{ clipPath: POLYGON_STAR, }}
                styleToMerge={{ clipPath: POLYGON_STAR, }}
                key="ImgInFrameMaskForDiv"
            >
                <SeparatorAbsSvg2
                    name="sepNormalRel2FullHorizontalHills"
                    id="img-voda-v-lese"
                    key="img-voda-v-lese"
                    optionsSep={{
                        styleSvg: {
                            height: "100px",
                            transform: 'rotateY(180deg)'
                        },
                        svgYPadding: 0,
                        isRelative: true,
                    }} >
                    <div
                        style={{
                            position: "relative", backgroundColor: sepFillColor,
                            color: sepFillColorContrastText
                        }}
                    >
                        <Container>
                            <FrameMaskForDiv
                                options={{
                                    frames: [ "top", "right", "bottom", "left" ],
                                    masks: { top: "mask12", right: "mask12V", bottom: "mask12", left: "mask12V" },
                                    rightFr: { isCornerMask: false }, bottomFr: { isCornerMask: false }, leftFr: { isCornerMask: false },
                                    styleAll: { zIndex: '2', }
                                }}
                            >
                                <FrameBgForDiv options={{
                                    bgImages: { top: "bg4", right: "bg4V", bottom: "bg4", left: "bg4V" },
                                    styleAll: { mixBlendMode: 'luminosity', },
                                    topFr: {
                                        isCornerBg: false,
                                        frameThickness: 100,
                                        xDiff: -10,
                                        yDiff: -10,
                                    },
                                    rightFr: {
                                        isCornerBg: false,
                                        frameThickness: 100,
                                        xDiff: -10,
                                        yDiff: -10,
                                    },
                                    bottomFr: {
                                        isCornerBg: false, frameThickness: 100,
                                        xDiff: -10,
                                        yDiff: -10,
                                    },
                                    leftFr: {
                                        isCornerBg: false, frameThickness: 100,
                                        xDiff: -10,
                                        yDiff: -10,
                                    }
                                }}
                                >
                                    <FrameBgForDiv options={{
                                        bgImages: { top: "bgc1TL", right: "bgc1TR", bottom: "bgc1BR", left: "bgc1BL" },
                                        styleAll: { mixBlendMode: 'soft-light', },
                                        topFr: {
                                            isCornerBg: true,
                                            frameThickness: 100,
                                            xDiff: -50,
                                            yDiff: -50,
                                        },
                                        rightFr: {
                                            isCornerBg: true,
                                            frameThickness: 100,
                                            xDiff: -50,
                                            yDiff: -50,
                                        },
                                        bottomFr: {
                                            isCornerBg: true, frameThickness: 100,
                                            xDiff: -50,
                                            yDiff: -50,
                                        },
                                        leftFr: {
                                            isCornerBg: true, frameThickness: 100,
                                            xDiff: -50,
                                            yDiff: -50,
                                        }
                                    }}
                                    >
                                        <div
                                            style={{
                                                width: '100%',
                                                padding: '10px 20px',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}
                                        >
                                            <AnimateScrollIntoViewport
                                                delay={1}
                                                durationIn={1.5}
                                                durationOut={0.2}
                                                animateFramer={{ opacity: 1 }}
                                                exitFramer={{ opacity: 0, }}
                                                styleToMerge={{
                                                    opacity: 0
                                                }}
                                                name="imgToAppear"
                                                key="imgToAppear"
                                            >
                                                <img
                                                    src={jpgVodaVLeseW1200h900DreamSmoothing}
                                                    alt="voda v lese"
                                                    loading="lazy"
                                                />
                                            </AnimateScrollIntoViewport>
                                        </div>
                                    </FrameBgForDiv>
                                </FrameBgForDiv>
                            </FrameMaskForDiv>
                        </Container>
                    </div>
                </SeparatorAbsSvg2>
            </AnimateScrollIntoViewport>
        </div>
    );
}

export default MainUp;


