import React, { useLayoutEffect, useState } from "react";
import { Box, useTheme } from "@mui/system";
import { getBgStaticPathWithAspectRatio } from "../../utils/staticPathsForBgImages"
import { useBreakpoint } from "./contextBreakpointMediaQ";


// const hiddenMask = "linear-gradient(black, transparent)" // `repeating-linear-gradient(to right, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 30px, rgba(0,0,0,1) 30px, rgba(0,0,0,1) 30px)`;

const defaultOptions = {
    bgImages: { top: "bg4", right: "bg4V", bottom: "bg1", left: "bg1V" },
    // cornerMasks:{topLeft:"mask20TL", topRight: "mask20TR", bottomRight: "mask20BR", bottomLeft: "mask20BL"},
    // masks: { top: "mask11Kruh", right: "mask11Kruh", bottom: "mask10V", left: "mask10" },
    frames: [ "top", "right", "bottom", "left" ],
    styleAll: {},
    topFr: {
        isCornerBg: false,
        frameThickness: 30,
        colors3ForLinGradient: [ "red", "primary.main", "red" ],
        xDiff: -20,
        yDiff: -20,
        style: {
            // WebkitMaskRepeat: 'repeat-x', maskRepeat: 'repeat-x'
        },
        classFr: "topFr"
    },
    rightFr: {
        isCornerBg: false,
        frameThickness: 30,
        colors3ForLinGradient: [ "primary.dark", "primary.main", "primary.light", ],
        xDiff: -20,
        yDiff: -20,
        classFr: "rightFr"
    },
    bottomFr: {
        isCornerBg: false,
        frameThickness: 30,
        colors3ForLinGradient: [ "transparent", "primary2.main", "transparent" ],
        xDiff: -20,
        yDiff: -20,
        classFr: "bottomFr"
    },
    leftFr: {
        isCornerBg: false,
        frameThickness: 30,
        colors3ForLinGradient: [ "primary.light", "primary.main", "primary.dark", ],
        xDiff: -20,
        yDiff: -20,

        classFr: "leftFr"
    },
}

const sxStyleWrap = (styleAll) => {
    return ({
        position: "relative",
        width: "inherit",
        zIndex: '0',
        "& .divFrameBg": {
            position: "absolute",
            mixBlendMode: 'luminosity',
            backgroundSize: "100%",
            zIndex: '1',

            ...styleAll,
        },
        "& img": {
            width: "inherit",
        },
    })
}



// ==================================================================
const FrameBgForDiv = ({ children, options = {
    ...defaultOptions,
}, defaults = { ...defaultOptions } }) => {
    // const startTime = performance.now();
    const deviceWidth = useBreakpoint()

    const { bgImages: bgImagesD, frames: framesD, leftFr: leftFrD, rightFr: rightFrD, topFr: topFrD, bottomFr: bottomFrD } = defaults
    let { frames, bgImages, leftFr, rightFr, topFr, bottomFr } = options
    const { styleAll = {} } = options

    frames = frames || framesD
    bgImages = bgImages || bgImagesD


    const isTop = frames.includes("top")
    const isRight = frames.includes("right")
    const isBottom = frames.includes("bottom")
    const isLeft = frames.includes("left")

    leftFr = isLeft ? { ...leftFrD, ...leftFr } : undefined
    rightFr = isRight ? { ...rightFrD, ...rightFr } : undefined
    topFr = isTop ? { ...topFrD, ...topFr } : undefined
    bottomFr = isBottom ? { ...bottomFrD, ...bottomFr } : undefined


    const staticPathAndAspectRatio = {
        top: isTop ? getBgStaticPathWithAspectRatio(bgImages.top) : undefined,
        right: isRight ? getBgStaticPathWithAspectRatio(bgImages.right) : undefined,
        bottom: isBottom ? getBgStaticPathWithAspectRatio(bgImages.bottom) : undefined,
        left: isLeft ? getBgStaticPathWithAspectRatio(bgImages.left) : undefined
    }

    return (

        <Box
            sx={{ ...sxStyleWrap(styleAll), }}
        >

            {isTop && <Frame deviceWidth={deviceWidth} fr={topFr} staticBgImgPath={staticPathAndAspectRatio.top?.path} aspectRatio={staticPathAndAspectRatio.top?.aspectRatio} pos="top" />}
            {isRight && <Frame deviceWidth={deviceWidth} fr={rightFr} staticBgImgPath={staticPathAndAspectRatio.right?.path} aspectRatio={staticPathAndAspectRatio.right?.aspectRatio} pos="right" />}
            {isBottom && <Frame deviceWidth={deviceWidth} fr={bottomFr} staticBgImgPath={staticPathAndAspectRatio.bottom?.path} aspectRatio={staticPathAndAspectRatio.bottom?.aspectRatio} pos="bottom" />}
            {isLeft && <Frame deviceWidth={deviceWidth} fr={leftFr} staticBgImgPath={staticPathAndAspectRatio.left?.path} aspectRatio={staticPathAndAspectRatio.left?.aspectRatio} pos="left" />}

            {children}
        </Box>
    )
}



const Frame = ({ deviceWidth, fr, staticBgImgPath, aspectRatio, pos = "top" }) => {
    const theme = useTheme()

    const {
        isCornerBg,
        colors3ForLinGradient,
        style = {},
        classFr
    } = fr

    const { xDiff, yDiff, frameThickness = 100 } = {
        sm: { xDiff: fr.xDiff / 2, yDiff: fr.yDiff / 2, frameThickness: fr.frameThickness / 4 },
        xs: { xDiff: fr.xDiff / 4, yDiff: fr.yDiff / 4, frameThickness: fr.frameThickness / 4 },
        md: { xDiff: fr.xDiff, yDiff: fr.yDiff, frameThickness: fr.frameThickness },
        lg: { xDiff: fr.xDiff, yDiff: fr.yDiff, frameThickness: fr.frameThickness },
        xl: { xDiff: fr.xDiff, yDiff: fr.yDiff, frameThickness: fr.frameThickness },
    }[ deviceWidth ] || {};

    const colors3Themed = colors3ForLinGradient.map((col => {

        const arr = col.split(".")

        if (arr.length === 2) {
            const arr = col.split(".")
            const newCol = theme.palette[ arr[ 0 ] ][ arr[ 1 ] ]
            return newCol
        }
        return col
    }))


    const getStylePosAndGradientDirection = () => {
        switch (pos) {
            case "top":
                return {
                    stylePos: {
                        top: `${yDiff}px`,
                        left: `${xDiff}px`,
                        width: isCornerBg ? `${aspectRatio * frameThickness}px` : `calc(100% - ${2 * xDiff}px)`,
                        height: `${frameThickness}px`,

                    },
                    gradientDirection: isCornerBg ? "to bottom right" : "to bottom"
                }

            case "right":
                return {
                    stylePos: {
                        top: `${yDiff}px`,
                        right: `${xDiff}px`,
                        width: isCornerBg ? `${aspectRatio * frameThickness}px` : `${frameThickness}px`,
                        height: isCornerBg ? `${frameThickness}px` : `calc(100% - ${2 * yDiff}px)`,
                    },
                    gradientDirection: isCornerBg ? "to bottom left" : "to left"
                }

            case "bottom":
                return {
                    stylePos: {
                        bottom: `${yDiff}px`,
                        left: isCornerBg ? "unset" : `${xDiff}px`,
                        right: isCornerBg ? `${xDiff}px` : "unset",
                        width: isCornerBg ? `${aspectRatio * frameThickness}px` : `calc(100% - ${2 * xDiff}px)`,
                        height: `${frameThickness}px`,
                    },
                    gradientDirection: isCornerBg ? "to top left" : "to top"
                }

            case "left":
                return {
                    stylePos: {
                        top: isCornerBg ? "unset" : `${yDiff}px`,
                        bottom: isCornerBg ? `${yDiff}px` : "unset",
                        left: `${xDiff}px`,
                        width: isCornerBg ? `${aspectRatio * frameThickness}px` : `${frameThickness}px`,
                        height: isCornerBg ? `${frameThickness}px` : `calc(100% - ${2 * yDiff}px)`,
                    },
                    gradientDirection: isCornerBg ? "to top right" : "to right"
                }

            default:
                return {};
        }
    }
    const { stylePos, gradientDirection } = getStylePosAndGradientDirection();
    const gradient3 = `linear-gradient(${gradientDirection},${colors3Themed[ 0 ]}, ${colors3Themed[ 1 ]}, ${colors3Themed[ 2 ]})`

    const [ isBgImgReadyToLoad, setIsBgImgReadyToLoad ] = useState(false)

    useLayoutEffect(() => {

        const imgBg = new Image();
        imgBg.src = staticBgImgPath;
        imgBg.onload = () => {
            // const endTime = performance.now();
            // const timeToRender = endTime - startTime;
            // console.log(`FrameBgForDiv.js: useEff timeToRender: ${timeToRender} ms`)
            setIsBgImgReadyToLoad(true)
            //2-nd alternative: imgBg.addEventListener('load', () => setIsBgImgReadyToLoad(true))
        }
    }, [ staticBgImgPath ]);

    return (
        <Box
            className={`${classFr} divFrameBg`}
            sx={{
                ...stylePos,
                background: gradient3,
                backgroundImage: isBgImgReadyToLoad ? `url(${staticBgImgPath})` : "unset",
                // but better would be to provide firstly prepared placeholder small image instead of nothing

            }}
            style={style}
            key={classFr}
        >
        </Box>
    )
}

export default FrameBgForDiv
